import { createContext, useState, useEffect } from'react';
import { data } from '../utils/data';

export interface ISample {
    id: string;
    name: string;
    src: string;
    url: string;
    repo:string;
    demo:string;
    descr: string;
    techstack: string;
}

export const samplesContext = createContext<ISample[]>([]);

interface Props {
    children: React.ReactNode;
}
const SamplesProvider: React.FC<Props>  = ({ children }) => {
    const [samples, setSamples] = useState<ISample[]>([]);
    useEffect(() => {
        const dataSamples = data;
        setSamples(dataSamples);
    }, []);

    return (    
        <samplesContext.Provider value={ samples }>
            { children }
        </samplesContext.Provider>
    )
}

export default SamplesProvider;