import { useEffect } from 'react';
import { usePage } from '../../../hooks';
import { _cleanHash } from '../../../utils/helpers';
import SpeechRecognition, 
    { useSpeechRecognition } from 'react-speech-recognition';
import styles from './Nav.module.scss';

const Nav: React.FC = () => {
    const page = usePage();
   
    useEffect(() => {
        let timer: NodeJS.Timeout;
        const anchors = document.querySelectorAll('nav a') as NodeListOf<HTMLAnchorElement>; 
        anchors.forEach( anchor => {
            anchor.addEventListener('click', (e) => {
                const target = e.target as HTMLAnchorElement;
                const top = document.querySelector('#panel_1') as HTMLDivElement;
        
                if(target.hash === '#home') {       
                    top.scrollIntoView();
                }
                
                timer = setTimeout(()=> {
                    _cleanHash();
                }, 5);
            });
        })

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <nav className={ `${ page.contact ? `${styles["global-nav"]} ${styles.end }`: styles["global-nav"]}` }>
            <div>
                <ul>
                    <li className={ page.home ? `${styles.active}` : '' }>
                        <a href="#home">Home</a>
                    </li>
                    <li className={ page.samples ? `${styles.active}` : '' }>
                        <a href="#samples">Samples</a>
                    </li>
                    <li className={ page.contact ? `${styles.active}` : '' }>
                        <a href="#contact">Contact</a> 
                    </li>
                    <span className={ styles.dot }></span>
                </ul>
            </div>
        </nav>
    )
}

export default Nav;
