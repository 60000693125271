import { useRef, useEffect } from 'react';
import { _setObservable, _sanitizeMarkup } from '../../../utils/helpers';
import { Icon } from '../../../components/shared';
import { icons } from '../../../utils/icons';

import sharedStyles from '../Samples.module.scss';
import styles from './Sample.module.scss';

interface Props {
    name: string;
    src: string;
    url: string;
    repo:string;
    demo:string;
    descr: string;
    techstack: string;
    i: number;
    setPanel: React.Dispatch<React.SetStateAction<string>>;
    rootRef: React.RefObject<HTMLDivElement>;
}

const Sample: React.FC<Props> = ({ 
    name, 
    src, 
    url, 
    repo,
    demo,
    descr, 
    techstack, 
    i, 
    setPanel, 
    rootRef 
}) => {
    const screenRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = _setObservable((entries: IntersectionObserverEntry[]) => {
            if(entries[0].isIntersecting) {
                setPanel(entries[0].target.id);  
                entries[0].target.classList.add(`${styles.active}`);
               
            } else {
                entries[0].target.classList.remove(`${styles.active}`);
            }
        }, {
            root: rootRef.current!,
            threshold: 0.2
        });

        observer.observe(screenRef.current!);

        return () => {
            observer.disconnect();
        }

    }, [rootRef, setPanel]);

      
    return (
        <div 
            id={ name } 
            style={{ left: `${ 100 * ( i + 1 )}%` }}
            className={ `${sharedStyles.panel} ${styles.panel}` }
            ref={ screenRef }>
                <div className={ styles.slide }>
                    <div className={ styles.item }>
                        <div className={ styles.thumb }>
                            <a href={ url || demo } target="_blank" rel="noreferrer">
                                <img src={ process.env.PUBLIC_URL + src } alt={ `screenshot-${name}` } />
                                <span>
                                    <Icon size="icon--xs" path={ process.env.PUBLIC_URL + icons.link } /> 
                                    <i className='sr-only'>View Demo</i>
                                </span>
                            </a>
                        </div>
                        <div className={ styles.detail }>
                            <div className={ styles.current }>
                                0{ i + 1 }.
                            </div> 
                            <h2>{ name }</h2>
                            <div className={ styles.descr } dangerouslySetInnerHTML={ _sanitizeMarkup(descr) }></div>   
                            <div className={ styles.techstack } dangerouslySetInnerHTML={ _sanitizeMarkup(techstack) }></div>   
                            <div className={ styles.links }>
                                { url && 
                                    <a href={ url } target="_blank" rel="noreferrer">
                                        <Icon size="icon--xs" path={ process.env.PUBLIC_URL + icons.link } /> 
                                        <span>Visit Website</span>
                                    </a>
                                }
                                { demo && 
                                    <a href={ demo } target="_blank" rel="noreferrer">
                                        <Icon size="icon--xs" path={ process.env.PUBLIC_URL + icons.link } /> 
                                        <span>View Demo</span>
                                    </a>
                                }
                                { repo && 
                                    <a href={ repo } target="_blank" rel="noreferrer">
                                        <Icon size="icon--xs" path={ process.env.PUBLIC_URL + icons.github_circle } /> 
                                        <span>View on Github</span>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Sample;
