import { usePage } from '../../hooks';
import styles from './Footer.module.scss';
const Footer: React.FC = () => {
  const page = usePage();

    return (
      <>
        { page.contact && 
          <footer className={ styles.footer }>
            <p >&copy; 2021 nozomione</p>
          </footer>
        }
      </>
    )
}

export default Footer;
