import { createContext, useState } from'react';

interface IPages {
    home: boolean;
    samples: boolean;
    contact: boolean;
}

export const pagesContext = createContext<IPages>({} as IPages);
export const setPagesContext = createContext<React.Dispatch<React.SetStateAction<IPages>> | null>(null);

interface Props {
    children: React.ReactNode;
}
const PagesProvider: React.FC<Props> = ({ children }) => {
    const [page, setPage] = useState<IPages>({} as IPages);

    return (
        <pagesContext.Provider value={ page }>
            <setPagesContext.Provider value={ setPage }>
                { children }
            </setPagesContext.Provider>
        </pagesContext.Provider>
    );
}

export default PagesProvider;
