import { CheckMark } from '../check';
import styles from './SuccessAnimation.module.scss';

const SuccessAnimation = () => {
    return (
        
         <div className={styles['success-animation']}>
             <div className={styles['envelope-wrapper']}>
                <div className={styles.envelope}>
                    <div className={styles.cover}></div>
                    <div className={styles.letter}>@</div>
                </div>
            </div>
            <CheckMark />
         </div>
        
    )
}

export default SuccessAnimation;

