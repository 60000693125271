import { useEffect } from 'react';
import { _cleanHash } from '../../utils/helpers';
import styles from './Logo.module.scss';

const Logo: React.FC = () => {
    useEffect(() => {
        const logo = document.querySelector(`.${styles.logo}`) as HTMLDivElement;
        let timer: NodeJS.Timeout;

        logo.addEventListener('click', () => {
            const top = document.querySelector('#panel_1') as HTMLDivElement;
            top.scrollIntoView();
    
            timer = setTimeout(()=> {
                _cleanHash();
            }, 5);
        })

        return () => {
            clearTimeout(timer);
        }
    }, [])

    return (
        <div className={ styles.logo }>
            <span className="sr-only">
                Nozomi One
            </span>
            <em className={ styles.n }>&#120263;.</em>
            <em className={ styles.o }>1</em>
        </div>
    )
}

export default Logo;
