import styles from './Header.module.scss';
// import { NavIon } from './nav-icon';

const Header: React.FC = () => {
    return (
        <header className={ styles.header }>
            <span className="sr-only">Nozomione.online</span>
        </header>
    )
}

export default Header;
