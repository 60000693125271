export const data = [
	{
		id: Math.random().toString(36).substr(2, 5),
		name: 'AddByte',
		src: 'thumbs/addbyte.png',
		url: 'https://www.addbyte.online',
		repo: '',
		demo: '',
		descr: `<p>A Social Media Platform for community building.</p>`,
		techstack: `<ul>
			<li>React.js</li>
			<li>Express.js</li>
			<li>Node.js</li>
			<li>MongoDB</li>
			<li>SASS</li>
        </ul>
         `,
	},
	{
		id: Math.random().toString(36).substr(2, 5),
		name: 'COVID-19 Tracker',
		src: 'thumbs/covid19-tracking.jpg',
		url: 'https://www.trackdata.online',
		repo: '',
		demo: '',
		descr: `<p>Interactive Covid-19 data tracking website utilizing open source APIs.</p>`,
		techstack: `<ul>
			<li>React.js</li>
			<li>Chart.js</li>
			<li>Mapbox.js</li>
			<li>JS</li>
			<li>SASS</li>
        </ul>
         `,
	},
	{
		id: Math.random().toString(36).substr(2, 5),
		name: 'Sticky notes',
		src: 'thumbs/stickies.jpg',
		url: '',
		repo: 'https://github.com/nozomione/sticky-notes-app',
		demo: 'https://nozomione.github.io/sticky-notes-app/',
		descr: `<p>Created using hooks and context API utilizing the local storage web API.</p>`,
		techstack: `<ul>
			<li>CSS/JS</li>
			<li>React.jS</li>
			<li>Typescript</li>
			<li>JS</li>
			<li>SASS</li>
        </ul>
         `,
	},
	{
		id: Math.random().toString(36).substr(2, 5),
		name: 'Todos',
		src: 'thumbs/todos.jpg',
		url: '',
		repo: 'https://github.com/nozomione/todos-app',
		demo: 'https://nozomione.github.io/todos-app/',
		descr: `<p>Created using hooks and context API with a simple drag and drop implementation.</p>`,
		techstack: `<ul>
			<li>React.jS</li>
			<li>Typescript</li>
			<li>JS</li>
			<li>SASS</li>
        </ul>
         `,
	},
];
