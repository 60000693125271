import { useState, useRef, useEffect } from 'react';
import { useSetPage, useMatchMedia } from '../../hooks';
import { _setObservable, _cleanHash } from '../../utils/helpers';
import { Skills } from '../../components/skills';
import { Mobile } from '../../components/mobile';
import { Cogs } from '../../components/cogs';
import sharedStyles from '../Pages.module.scss';
import styles from './Home.module.scss';
import { Icon } from '../../components/shared';
import { icons } from '../../utils/icons';

const Home: React.FC = () => {
    const setPage = useSetPage()!;
    const maxW950 = useMatchMedia('(max-width: 950px)');
    const maxW780 = useMatchMedia('(max-width: 780px)');
    const maxW600 = useMatchMedia('(max-width: 600px)');
    const [ scrollY, setScrollY ] = useState(0);
    const screenRef = useRef<HTMLElement>(null);
    
    useEffect(() => {
        const scrollTarget =  screenRef.current!.querySelector(`.${styles.container}`) as HTMLDivElement;
        const arrow =  screenRef.current!.querySelector(`.${styles.arrow}`) as HTMLAnchorElement;
        const panels = scrollTarget.querySelectorAll(`.${styles.panel}`) as NodeListOf<Element>;  
        let timer: NodeJS.Timeout;

        if(maxW950) {
            arrow.addEventListener('click', () => {
                const panel2 = document.querySelector('#panel_2') as HTMLDivElement;
                panel2.scrollIntoView();
        
                timer = setTimeout(()=> {
                    _cleanHash();
                }, 5);
            })
        }

        if(!maxW950) {
            var svgs = scrollTarget.querySelectorAll(`.${styles.svg} svg`) as NodeListOf<SVGElement>;  
            var handleScroll = () => {
                setScrollY(scrollTarget.scrollTop);
                svgs.forEach( svg => {
                        if(scrollY === 0) {
                            svg.style.transform = `translate3d(0, 0, 0)`;
                        }
                        let speed = scrollY * Number(svg.dataset.speed);
                        svg.style.transform = `translate3d(0, -${speed}px, 0)`;
                    });
            };

            scrollTarget.addEventListener("scroll", handleScroll);    
        }
        
        const modify = (el: IntersectionObserverEntry) => {    
            if(el.target.id === 'home') {
                setPage({
                    home: true,
                    samples: false,
                    contact: false
                });   
                el.target.classList.add(`${sharedStyles.active}`, `${styles.active}`);
            } else {
                el.target.classList.add(`${sharedStyles["panel-active"]}`, `${styles["panel-active"]}`);
            }
        };

        const reset = (el: IntersectionObserverEntry) => {
            if(el.target.id === 'home') {
                el.target.classList.remove(`${sharedStyles.active}`, `${styles.active}`);
            } else {
                el.target.classList.remove(`${sharedStyles["panel-active"]}`, `${styles["panel-active"]}`);
            }
        };

        const observer = _setObservable((entries: IntersectionObserverEntry[]) => {
            entries.forEach( entry => { 
                if(entry.isIntersecting) {
                    modify(entry);
                } else {
                    reset(entry);
                }
            })
        }, { threshold: 0.2 })

        observer.observe(screenRef.current!);
        panels.forEach(panel => {
            observer.observe(panel);
        });
      
        return () => {
            if(maxW950) {
                clearTimeout(timer);
            }

            if(!maxW950) {
                scrollTarget.removeEventListener("scroll", handleScroll);
            }
            observer.disconnect();
        }
    }, [setPage, scrollY, maxW950]);

    return (
        <section 
            id="home" 
            className={ styles.home }
            ref={ screenRef }>
                <div 
                    className={`${sharedStyles.container} ${styles.container}`}>
                    <div id="panel_1" className={`${sharedStyles.panel} ${styles.panel}`}>
                        <h1 style={{ transform: `translate3d(0, -${scrollY * 0.01}px, 0)`}}>
                            nozomione.<br/>online
                        </h1>
                        <h3  style={{ transform: `translate3d(0, -${scrollY * 0.35}px, 0)`}}>A UX / UI Developer</h3>
                        <div className={ `${styles["text-box"]} ${styles.sticky}` }>
                            <h2>My name is Nozomi.</h2>
                            <p style={{ lineHeight: '2' }}>I am self-motivated, open minded, and down to earth person.</p>
                        </div>  
                        <a className={ styles.arrow } href="#panel_2">
                            <Icon size="icon--m" path={ process.env.PUBLIC_URL + icons.arrow_down } />
                            <span className='sr-only'>Down</span>
                        </a>
                    </div> 
                   
                    <div id="panel_2" className={`${sharedStyles.panel} ${styles.panel}`}>  
                        <div className={ `${styles["text-box"]} ${styles.sticky}` }>
                            { !maxW780 && <Mobile />}
                            { !maxW600 && <div className={`${styles.book}`}>
                                <div className={styles.pages}>
                                    <div className={styles.page}></div>
                                    <div className={styles.page}></div>
                                    <div className={styles.page}></div>
                                    <div className={styles.page}></div>
                                    <div className={styles.page}></div>
                                    <div className={styles.page}></div>
                                    <div className={styles.page}></div>
                                    <div className={styles.page}></div>
                                    <div className={styles.page}></div>
                                    <div className={styles.page}></div>
                                    <div className={styles.last}></div>
                                </div>
                            </div>}
                            <div className={`${styles.inner}`}>
                                <h2>A little bit about myself</h2>
                                <p>I've been working as a UX / UI developer since 2007.</p>
                                <p>Years of work experience as a UI developer, I've been fortunate to meet and collaborate with amazing creators from all over the world in various projects including public facing branded content websites, 
                                intranet applications, and Sass products.</p>
                                <p>Having a Multimedia Design(interactive design) background, my typical workflow involves in both UX creation and UI development.</p>
                                <p>In the process of making, my primary focus is to build a product that makes people feel good.</p>
                                <p>Please visit my <a href="#home" target="_blank">LinkedIn.</a></p> 
                            </div>
                        </div>
                    </div> 

                    { !maxW950  &&  <div id="panel_3" className={ `${sharedStyles.panel} ${styles.panel}` }>
                        <div className={ `${styles["text-box"]} ${styles.sticky}` }> 
                            <h2>Quick Skill Overview</h2>
                            <Skills />
                            <Cogs />
                        </div> 
                    </div> }
                    { !maxW950  &&   
                        <div className={ styles.svg }>
                            <svg data-speed="0.5" viewBox="0 0 341 113" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                <path d="M77.0469 105H63.8535V58.9941H13.6777V105H0.552734V5.46875H13.6777V48.2617H63.8535V5.46875H77.0469V105Z" />
                            </svg>
                            <svg data-speed="0.7" viewBox="0 0 341 113" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                <path d="M129.068 106.367C119.042 106.367 110.885 103.086 104.596 96.5234C98.3066 89.9154 95.1621 81.097 95.1621 70.0684V67.7441C95.1621 60.4069 96.5521 53.8672 99.332 48.125C102.158 42.3372 106.077 37.8255 111.09 34.5898C116.148 31.3086 121.617 29.668 127.496 29.668C137.112 29.668 144.586 32.8353 149.918 39.1699C155.25 45.5046 157.916 54.5736 157.916 66.377V71.6406H107.809C107.991 78.9323 110.11 84.834 114.166 89.3457C118.268 93.8118 123.463 96.0449 129.752 96.0449C134.218 96.0449 138.001 95.1335 141.1 93.3105C144.199 91.4876 146.91 89.0723 149.234 86.0645L156.959 92.0801C150.761 101.605 141.464 106.367 129.068 106.367ZM127.496 40.0586C122.392 40.0586 118.108 41.9271 114.645 45.6641C111.181 49.3555 109.039 54.5508 108.219 61.25H145.27V60.293C144.905 53.8672 143.173 48.8997 140.074 45.3906C136.975 41.8359 132.783 40.0586 127.496 40.0586Z" />
                            </svg>
                            <svg data-speed="0.65" viewBox="0 0 341 113" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                <path d="M186.354 105H173.707V0H186.354V105Z" />
                            </svg>
                            <svg data-speed="0.45" viewBox="0 0 341 113" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                <path d="M220.396 105H207.75V0H220.396V105Z" />
                            </svg>
                            <svg  data-speed="0.8"viewBox="0 0 341 113" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                <path d="M237.35 67.334C237.35 60.0879 238.762 53.571 241.588 47.7832C244.459 41.9954 248.424 37.5293 253.482 34.3848C258.587 31.2402 264.397 29.668 270.914 29.668C280.986 29.668 289.12 33.1543 295.318 40.127C301.562 47.0996 304.684 56.3737 304.684 67.9492V68.8379C304.684 76.0384 303.294 82.5098 300.514 88.252C297.779 93.9486 293.837 98.3919 288.688 101.582C283.583 104.772 277.704 106.367 271.051 106.367C261.025 106.367 252.89 102.881 246.646 95.9082C240.449 88.9355 237.35 79.707 237.35 68.2227V67.334ZM250.064 68.8379C250.064 77.041 251.956 83.6263 255.738 88.5938C259.566 93.5612 264.671 96.0449 271.051 96.0449C277.477 96.0449 282.581 93.5384 286.363 88.5254C290.146 83.4668 292.037 76.403 292.037 67.334C292.037 59.222 290.1 52.6595 286.227 47.6465C282.398 42.5879 277.294 40.0586 270.914 40.0586C264.671 40.0586 259.635 42.5423 255.807 47.5098C251.979 52.4772 250.064 59.5866 250.064 68.8379Z"/>
                            </svg>
                            <svg data-speed=".45" viewBox="0 0 341 113" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">   
                                <path d="M334.693 76.9043H323.277L322.389 5.46875H335.65L334.693 76.9043ZM321.91 98.6426C321.91 96.5918 322.525 94.8828 323.756 93.5156C325.032 92.1029 326.9 91.3965 329.361 91.3965C331.822 91.3965 333.691 92.1029 334.967 93.5156C336.243 94.8828 336.881 96.5918 336.881 98.6426C336.881 100.693 336.243 102.402 334.967 103.77C333.691 105.091 331.822 105.752 329.361 105.752C326.9 105.752 325.032 105.091 323.756 103.77C322.525 102.402 321.91 100.693 321.91 98.6426Z" />
                            </svg>   
                            <svg data-speed="0.5" viewBox="0 0 341 113" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                <path d="M81.0469 111H67.8535V64.9941H17.6777V111H4.55273V11.4688H17.6777V54.2617H67.8535V11.4688H81.0469V111Z" />
                            </svg>
                            <svg data-speed="0.7" viewBox="0 0 341 113" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">  
                                <path d="M133.068 112.367C123.042 112.367 114.885 109.086 108.596 102.523C102.307 95.9154 99.1621 87.097 99.1621 76.0684V73.7441C99.1621 66.4069 100.552 59.8672 103.332 54.125C106.158 48.3372 110.077 43.8255 115.09 40.5898C120.148 37.3086 125.617 35.668 131.496 35.668C141.112 35.668 148.586 38.8353 153.918 45.1699C159.25 51.5046 161.916 60.5736 161.916 72.377V77.6406H111.809C111.991 84.9323 114.11 90.834 118.166 95.3457C122.268 99.8118 127.463 102.045 133.752 102.045C138.218 102.045 142.001 101.133 145.1 99.3105C148.199 97.4876 150.91 95.0723 153.234 92.0645L160.959 98.0801C154.761 107.605 145.464 112.367 133.068 112.367ZM131.496 46.0586C126.392 46.0586 122.108 47.9271 118.645 51.6641C115.181 55.3555 113.039 60.5508 112.219 67.25H149.27V66.293C148.905 59.8672 147.173 54.8997 144.074 51.3906C140.975 47.8359 136.783 46.0586 131.496 46.0586Z" />
                            </svg>
                            <svg data-speed="0.65" viewBox="0 0 341 113" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">    
                                <path d="M190.354 111H177.707V6H190.354V111Z" />
                            </svg>
                            <svg data-speed="0.45" viewBox="0 0 341 113" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">    
                                <path d="M224.396 111H211.75V6H224.396V111Z" />
                            </svg>
                            <svg data-speed="0.8" viewBox="0 0 341 113" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">    
                                <path d="M241.35 73.334C241.35 66.0879 242.762 59.571 245.588 53.7832C248.459 47.9954 252.424 43.5293 257.482 40.3848C262.587 37.2402 268.397 35.668 274.914 35.668C284.986 35.668 293.12 39.1543 299.318 46.127C305.562 53.0996 308.684 62.3737 308.684 73.9492V74.8379C308.684 82.0384 307.294 88.5098 304.514 94.252C301.779 99.9486 297.837 104.392 292.688 107.582C287.583 110.772 281.704 112.367 275.051 112.367C265.025 112.367 256.89 108.881 250.646 101.908C244.449 94.9355 241.35 85.707 241.35 74.2227V73.334ZM254.064 74.8379C254.064 83.041 255.956 89.6263 259.738 94.5938C263.566 99.5612 268.671 102.045 275.051 102.045C281.477 102.045 286.581 99.5384 290.363 94.5254C294.146 89.4668 296.037 82.403 296.037 73.334C296.037 65.222 294.1 58.6595 290.227 53.6465C286.398 48.5879 281.294 46.0586 274.914 46.0586C268.671 46.0586 263.635 48.5423 259.807 53.5098C255.979 58.4772 254.064 65.5866 254.064 74.8379Z" />
                            </svg>
                            <svg data-speed=".45" viewBox="0 0 341 113" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">    
                                <path d="M338.693 82.9043H327.277L326.389 11.4688H339.65L338.693 82.9043ZM325.91 104.643C325.91 102.592 326.525 100.883 327.756 99.5156C329.032 98.1029 330.9 97.3965 333.361 97.3965C335.822 97.3965 337.691 98.1029 338.967 99.5156C340.243 100.883 340.881 102.592 340.881 104.643C340.881 106.693 340.243 108.402 338.967 109.77C337.691 111.091 335.822 111.752 333.361 111.752C330.9 111.752 329.032 111.091 327.756 109.77C326.525 108.402 325.91 106.693 325.91 104.643Z" />
                            </svg>
                            <svg id={styles.bubble} data-speed="0.5"  viewBox="0 0 211 134" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                <path d="M0,43.0048684 C0,31.9564846 8.95245517,23 20.002454,23 L28.8564453,23 L24.3896484,0 L56.1552734,23 L190.99469,23 C202.043318,23 211,31.9544195 211,43.0048684 L211,113.995132 C211,125.043515 202.051567,134 191.007033,134 L19.992967,134 C8.95115621,134 0,125.045581 0,113.995132 L0,43.0048684 Z" />
                            </svg>
                        </div> 
                    }
                </div>

                { !maxW950  && 
                    <ul className={ styles.bg }>
                        <li style={{ transform: `translate3d(0, -${scrollY * 1.8}px, 0)`}}></li>
                        <li style={{ transform: `translate3d(0, -${scrollY * 0.5}px, 0)`}}></li>
                    </ul> 
                }
        </section>
    )
}

export default Home;
