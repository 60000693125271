import { Pages } from './pages';
import './App.scss';

const App: React.FC = () => {
  return (
    <>
      <Pages />
    </>
  );
}

export default App;
