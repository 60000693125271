import styles from './CheckMark.module.scss'

const CheckMark = () => {
    return (
        <div className={styles['check-mark']}>
			<div className={styles.icon}></div>
		</div>
    )
}

export default CheckMark;
