import Icon  from '../icon/Icon';
import { icons } from '../../../utils/icons';
import styles from './Socials.module.scss';

interface Props {
    direction?: 'x' | 'y';
    text?: boolean;
}

const Socials: React.FC<Props> = ({ direction, text }) => {
    return (
        <ul className={ `${styles.socials} ${styles[direction ? direction : '']}` }>
            <li>
                <a 
                href="https://github.com/nozomione" 
                target="_blank" 
                rel="noreferrer">
                    <Icon size="icon--s" path={ process.env.PUBLIC_URL + icons.github_outlined } /> 
                    <span className={`${text ? '' : 'sr-only'}`}>Visit my Github</span>                            
                </a>
            </li>
            <li>
                <a 
                href="https://www.linkedin.com/in/nozomiichihara" 
                target="_blank" 
                rel="noreferrer">
                    <Icon size="icon--s" path={ process.env.PUBLIC_URL + icons.linkedin_outlined } />
                    <span className={`${text ? '' : 'sr-only'}`}>Visit my Linkedin</span>
                </a>
            </li>
            <li>
                <a 
                href="https://freshcapsule.com" 
                target="_blank" 
                rel="noreferrer">
                    <Icon size="icon--s" path={ process.env.PUBLIC_URL + icons.website } />
                    <span className={`${text ? '' : 'sr-only'}`}>Visit my website</span>
                </a>
            </li>
        </ul>
    )
}

export default Socials;
