import sanitizeHtml from 'sanitize-html';

export const _setH1Text = (query: string, text: string ) => {
    const content = document.querySelector(query) as HTMLElement;
    content.style.setProperty('--h1-content', `'${text}'`);
}

export const _setObservable = (cb: IntersectionObserverCallback, options?: IntersectionObserverInit ) => {
        const observer = new IntersectionObserver(cb, options);
        return observer;
}

export const _cleanHash = () => {
    window.history.replaceState(null, document.title, window.location.origin + window.location.pathname);
}

export const _sanitizeMarkup = (markup: string) => {
    return { __html: sanitizeHtml(markup) }
}