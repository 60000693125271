import {Navbar} from './navbar';
import {Footer} from './footer';
import styles from './Layout.module.scss';
import { Header } from './header';

const Layout: React.FC = ({children}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.grid}>
				<Header />
				<Navbar />
				<main>
					<div className={styles.pages}>{children}</div>
				</main>
				<Footer />
			</div>
		</div>
	);
};

export default Layout;
