import { CardStateProvider, Card, Icon } from '../shared';
import { icons } from '../../utils/icons';
import styles from './Skills.module.scss';

const Skills = () => {
    return (
    <div className={ styles.skills }>
        <CardStateProvider>
            <Card i={ 0 } >
                <Card.Front>
                    <Icon size="icon--s" path={ process.env.PUBLIC_URL + icons.ux } />
                    <h3>Ux Design</h3>
                    <p>I appreciate simple yet sophisticated UX flow. <br />I collaborate with UX designers to create an intuitive 
                        and human-centered experience.</p>
                </Card.Front>
                <Card.Back>
                    <div className={ styles.tools}>
                        <h5>Utilized in past projects</h5>
                        <ul>
                            <li>Sketch.io</li>
                            <li>Figma</li>
                            <li>InVision</li>
                            <li>Adobe Photoshop</li>
                            <li>HTML/CSS/JavaScript</li>
                            <li>Powerpoint</li>
                            <li>Google docs</li>
                            <li>Mindmap</li>
                        </ul>
                        <h5>Familiar</h5>
                        <ul> 
                            <li>GitMind</li> 
                            <li>Marvel</li>  
                            <li>Flow Map</li>
                        </ul>
                    </div>
                </Card.Back>
            </Card>
            <Card i={ 1 }>
                <Card.Front>
                    <Icon size="icon--s" path={ process.env.PUBLIC_URL + icons.code } />
                    <h3>UI Development</h3>
                    <p>I truly enjoy the process of UX/UI development. It inspires me to think outside of the box and to stretch my perspective. </p>
                </Card.Front>
                <Card.Back>
                    <div className={ styles.tools}>
                        <h5>Utilized in past projects</h5>
                        <ul>
                            <li>HTML/CSS</li>
                            <li>SASS/SCSS</li>
                            <li>JavaScript</li>
                            <li>ReactJS</li>
                            <li>AngularJS</li>
                            <li>Angular v2+</li>
                        </ul>
                        <h5>Familiar</h5>
                        <ul>  
                            <li>Node.js</li>  
                            <li>Express.js</li>
                            <li>MongoDB</li>
                            <li>Vue.js</li>
                            <li>Next.js</li>
                            <li>SQL</li>
                            <li>Java</li>
                        </ul>
                    </div>
                </Card.Back>
            </Card>
            <Card i={ 2 }>
                <Card.Front>
                    <Icon size="icon--s" path={ process.env.PUBLIC_URL + icons.team } />
                        <h3>Work ethic</h3>
                        <p>I love helping others as well as 
                            knowledge sharing. I believe that understanding and trust are the essence of teamwork.</p>
                </Card.Front>
                <Card.Back>
                    <div className={ styles.tools }>
                        <h5>What I value</h5>
                        <ul>
                            <li>Adaptability</li>
                            <li>Communication</li>
                            <li>Cooperation</li>
                            <li>Empathy</li>
                            <li>Encouragement</li>
                            <li>Kindness</li>   
                            <li>Light-heartedness</li>
                            <li>Listening</li>
                            <li>Perseverance</li>
                            <li>Problem Solving</li>
                            <li>Reliability</li>
                            <li>Respect</li>
                            <li>Understanding</li>
                        </ul>
                    </div>
                </Card.Back>
            </Card>
        </CardStateProvider>
    </div>
    )
}

export default Skills;
