export const icons: {
    [ key: string ]: string;
} = {
    arrow: '/icons/arrow.svg#arrow',  
    arrow_down: '/icons/arrow_down.svg#arrow_down',  
    arrow_outline: '/icons/arrow_outline.svg#arrow_outline',  
    code: '/icons/code.svg#code',
    contact: '/icons/contact.svg#contact',
    flip:'/icons/flip.svg#flip',
    github: '/icons/github.svg#github',
    github_circle: '/icons/github_circle.svg#github_circle',
    github_outlined: '/icons/github_outlined.svg#github_outlined',
    home: '/icons/home.svg#home',
    left_arrow: '/icons/left_arrow.svg#left_arrow',
    right_arrow: '/icons/right_arrow.svg#right_arrow',  
    l_arrow_filled: '/icons/l_arrow_filled.svg#l_arrow_filled',  
    r_arrow_filled: '/icons/r_arrow_filled.svg#r_arrow_filled',  
    link: '/icons/link.svg#link',   
    linkedin: '/icons/linkedin.svg#linkedin',   
    linkedin_circle: '/icons/linkedin_circle.svg#linkedin_circle',  
    linkedin_outlined: '/icons/linkedin_outlined.svg#linkedin_outlined',   
    sample: '/icons/sample.svg#sample',
    team: '/icons/team.svg#team',
    ux: '/icons/ux.svg#ux',
    website: '/icons/website.svg#website',
    website_line: '/icons/website_circle.svg#website',
}