import {useRef, useEffect, Fragment} from 'react';
import {useSetPage, useSamples, useMatchMedia} from '../../../hooks';
import {
	_setObservable,
	_setH1Text,
	_sanitizeMarkup,
} from '../../../utils/helpers';
import {Icon} from '../../../components/shared';
import {icons} from '../../../utils/icons';
import styles from './MSamples.module.scss';

const MSamples = () => {
	const samples = useSamples();
	const setPage = useSetPage()!;
	const maxW780 = useMatchMedia('(max-width:780px)');
	const maxW540 = useMatchMedia('(max-width:540px)');
	const screenRef = useRef<HTMLElement>(null);

	useEffect(() => {
		const h1 = screenRef.current!.querySelector('h1') as HTMLHeadingElement;
		_setH1Text('#samples', h1.textContent!);
		let timer: NodeJS.Timeout;
		const observer = _setObservable(
			(entries: IntersectionObserverEntry[]) => {
				if (entries[0].isIntersecting) {
					setPage({
						home: false,
						samples: true,
						contact: false,
					});
					entries[0].target.classList.add(`${styles.active}`);
				} else {
					entries[0].target.classList.remove(`${styles.active}`);
				}
			},
			{threshold: 0.7}
		);

		observer.observe(screenRef.current!);

		return () => {
			observer.disconnect();
			clearTimeout(timer);
		};
	}, [setPage]);

	const renderSample = () =>
		samples.map((sample, i) => (
			<Fragment key={sample.id}>
				<div className={styles.header}>
					{maxW780 && (
						<h2>
							<span>0{i + 1}.</span> {sample.name}
						</h2>
					)}
					{maxW540 && (
						<div className={styles.links}>
							<a href={sample.url} target="_blank" rel="noreferrer">
								<Icon
									size='icon--xs'
									path={process.env.PUBLIC_URL + icons.link}
								/>
								<span className='sr-only'>View Demo</span>
							</a>
							<a href={sample.url} target="_blank" rel="noreferrer">
								<Icon
									size='icon--xs'
									path={process.env.PUBLIC_URL + icons.github_circle}
								/>
								<span className='sr-only'>View on Github</span>
							</a>
						</div>
					)}
				</div>
				<div className={styles.item}>
					<div className={styles.thumb}>
						<img
							src={process.env.PUBLIC_URL + sample.src}
							alt={`screenshot-${sample.name}`}
						/>
					</div>
					{!maxW780 && (
						<div className={styles.title}>
							<h2>{sample.name}</h2>
						</div>
					)}
					<div className={styles['text-box']}>
						<div
							className={styles.descr}
							dangerouslySetInnerHTML={_sanitizeMarkup(sample.descr)}
						></div>
						<div
							className={styles.techstack}
							dangerouslySetInnerHTML={_sanitizeMarkup(sample.techstack)}
						></div>
					</div>
					{!maxW540 && (
						<div className={styles.links}>
							{sample.url && (
								<a href={sample.url} target='_blank' rel='noreferrer'>
									<Icon
										size='icon--xs'
										path={process.env.PUBLIC_URL + icons.link}
									/>
									<span className='sr-only'>Visit Website</span>
								</a>
							)}
							{sample.repo && (
								<a href={sample.demo} target='_blank' rel='noreferrer'>
									<Icon
										size='icon--xs'
										path={process.env.PUBLIC_URL + icons.link}
									/>
									<span className='sr-only'>View Demo</span>
								</a>
							)}
							{sample.repo && (
								<a href={sample.repo} target='_blank' rel='noreferrer'>
									<Icon
										size='icon--xs'
										path={process.env.PUBLIC_URL + icons.github_circle}
									/>
									<span className='sr-only'>View on Github</span>
								</a>
							)}
						</div>
					)}
				</div>
			</Fragment>
		));

	return (
		<section id='samples' className={styles.samples} ref={screenRef}>
			<div className={styles.container}>
				<h1>Samples</h1>
				<p>Most of the past projects I contributed are private, either intranets or authenticated applications. And due to the contract agreements, I can't share them in public.</p>
                <p>With that, here are some of my code examples. Thank you for your understanding &#9787;</p>
				<div className={styles.panel}>
					<div className={styles.list}>{renderSample()}</div>
				</div>
			</div>
		</section>
	);
};

export default MSamples;
