import { createContext, useContext, useState, Children } from 'react';

interface ICard {
    [key: string]: boolean;
}

const flipContext = createContext<ICard[]>([]);
const setFlipContext = createContext<React.Dispatch<React.SetStateAction<ICard[]>> | null>(null);

const useFlip = () => useContext(flipContext);
const useSetFlip = () => useContext(setFlipContext);

type Props = {
    children: React.ReactNode;
}

const CardStateProvider: React.FC<Props> = ({ children }) => {
    let initialState: ICard[] = [];
    
    for(let i = 0, len = Children.count(children); i < len; i++) {
        initialState.push({
            [`child_${i}`]: false
        });
    }
    
    const [flip, setFlip] = useState<ICard[]>(initialState);

    return(
        <flipContext.Provider value={ flip }>
            <setFlipContext.Provider value={ setFlip }>
                    { children }
            </setFlipContext.Provider>
        </flipContext.Provider>
    );
}

export { useFlip, useSetFlip };
export default CardStateProvider;