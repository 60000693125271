import { FC, useState, useEffect } from 'react';
import { useFlip, useSetFlip } from './card-state';
import Icon from '../icon/Icon';
import { icons } from '../../../utils/icons';
import styles from './Card.module.scss';

interface Props {
    i: number;
}

const Card: FC<Props> & { Front: FC, Back: FC } = ({ i, children }) => {
    const [toggle, setToggle] = useState(false);
    const flip = useFlip();
    const setFlip = useSetFlip()!;

    useEffect(() => {
    }, [flip, toggle])

    const handleClick= () => {
        setToggle(!toggle);
        setFlip(prev =>  {
            let result = [...prev];
            result[i][`child_${i}`] = toggle;
            return result;
        });
    }

    return (
        <div className={ toggle ? `${styles.card} ${styles.flip}` : styles.card }>
            <div 
                className={ styles.toggle } 
                onClick={ handleClick }>
                <Icon size="icon--s" path={ process.env.PUBLIC_URL + icons.flip } />
                <span className="sr-only">Flip this card</span>
            </div>
            <div className={ styles.inner } >
                <div>
                    { children }
                </div>
            </div>
        </div>
    )
}

const Front: FC = ({ children }) => (
    <div className={ styles.front }>
        { children }
    </div>
);

const Back: FC = ({ children }) => (
    <div className={ styles.back }>
        { children }
    </div>
);

Card.Front = Front;
Card.Back = Back;

export default Card;
