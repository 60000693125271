import {PagesProvider, SamplesProvider} from '../context';
import { useMatchMedia } from '../hooks';
import { Layout } from '../layout';
import { Home } from './home';
import { Samples } from './samples';
import { Contact } from './contact';
import { MSamples } from './mobile/m-samples';

const Pages: React.FC = () => {
    const maxW950 = useMatchMedia('(max-width:950px)');

    return (
        <PagesProvider>
            <Layout>
                <Home />
                <SamplesProvider>
                { !maxW950 &&  <Samples /> }
                { maxW950 && <MSamples /> }
                </SamplesProvider> 
                <Contact />
            </Layout>
        </PagesProvider>
    )
}

export default Pages;
