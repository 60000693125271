import { useSamples } from '../../../hooks';
import { Sample } from '../sample';

interface Props {
    setPanel: React.Dispatch<React.SetStateAction<string>>;
    rootRef: React.RefObject<HTMLDivElement>;
}
const ListSamples: React.FC<Props> = ({ setPanel, rootRef }) => {
    const samples = useSamples();
    const renderList = () => 
        samples.map((sample, i) => 
            <Sample 
                key={ sample.id } 
                name={ sample.name }
                src={ sample.src }
                url={ sample.url }
                repo={ sample.repo }
                demo={ sample.demo }
                descr={ sample.descr }
                techstack={ sample.techstack }
                i={ i }
                setPanel={ setPanel }
                rootRef={ rootRef }
            />
    )

    return (
        <>
            { renderList() }
        </>
    )
}

export default ListSamples;
