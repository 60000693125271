import { Socials } from '../../components/shared';
import styles from './Mobile.module.scss';

const Mobile = () => {
    return (
        <div className={ styles.mobile }>
            <div  className={ styles.links }>
                <Socials direction='x' />
            </div>
            <svg className={ styles.body } viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path d="M143.72,0H56.278c-7.673,0-13.953,6.28-13.953,13.955v172.09c0,7.677,6.28,13.955,13.953,13.955h87.442   c7.675,0,13.955-6.278,13.955-13.955V13.955C157.675,6.28,151.395,0,143.72,0z M154.883,186.045   c0,6.158-5.007,11.165-11.163,11.165H56.278c-6.156,0-11.163-5.007-11.163-11.165V13.955c0-6.156,5.007-11.165,11.163-11.165   h87.442c6.156,0,11.163,5.009,11.163,11.165V186.045z"/>
                <path d="M143.72,4.651h-25.117v1.859c0,0.715-0.272,1.429-0.815,1.975c-0.546,0.545-1.261,0.817-1.973,0.817H84.186   c-0.714,0-1.427-0.272-1.973-0.817c-0.545-0.546-0.819-1.26-0.819-1.975V4.651H56.278c-5.126,0-9.299,4.172-9.299,9.303v172.09   c0,5.131,4.172,9.303,9.299,9.303h87.442c5.129,0,9.301-4.172,9.301-9.303V13.955C153.021,8.824,148.849,4.651,143.72,4.651z    M67.443,12.365c1.816,0,3.293,1.474,3.293,3.295c0,1.818-1.477,3.293-3.293,3.293c-1.819,0-3.295-1.475-3.295-3.293   C64.148,13.839,65.624,12.365,67.443,12.365z M100,192.096c-3.683,0-6.667-2.987-6.667-6.671c0-3.681,2.984-6.663,6.667-6.663   s6.667,2.982,6.667,6.663C106.667,189.108,103.683,192.096,100,192.096z M146.51,175.505H53.488V26.667h93.022V175.505z"/>
            </svg>
        </div>
    )
}

export default Mobile;
