import { Logo } from '../../components/logo';
import { Nav } from './nav';
import { NavIon } from './nav-icon';
import styles from './Navbar.module.scss';

const Navbar: React.FC = () => {
    return (
        <div className={ styles.navbar }>
           <Logo />
           <Nav />
           <NavIon />
        </div>
    )
}

export default Navbar;
