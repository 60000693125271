import { useState, useEffect } from 'react';
import { usePage } from '../../../hooks';
import { _cleanHash } from '../../../utils/helpers';
import { Icon } from '../../../components/shared';
import { icons } from '../../../utils/icons';
import styles from './NavIcon.module.scss';

const NavIon: React.FC = () => {
    const page = usePage();
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        const anchors = document.querySelectorAll('.nav-mobile a') as NodeListOf<HTMLAnchorElement>; 
        
        anchors.forEach( anchor => {
            anchor.addEventListener('click', (e) => {
                const target = e.target as HTMLAnchorElement;
                const top = document.querySelector('#panel_1') as HTMLDivElement;
        
                if(target.hash === '#home') {       
                    top.scrollIntoView();
                } 
                
                timer = setTimeout(()=> {
                    _cleanHash();
                }, 5);
            });
        })

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <div 
            className={  toggle ? 
                `${ styles['global-nav'] } ${ styles.expanded }` : styles['global-nav'] }
            onClick={ () => setToggle(prev => !prev)}>
            <div>
                <div className={ styles.logo }>
                    <span className="sr-only">
                        Nozomi One
                    </span>
                    <em className={ styles.n }>&#120263;.</em>
                    <em className={ styles.o }>1</em>
                </div>
                {/* <div className={ styles.trigger }>
                    <span></span>
                </div> */}
                <nav className='nav-mobile'>
                    <ul>
                        <li className={ page.home ? `${styles.active}` : '' }>
                            <a href="#home">
                                <Icon size='icon--xs' path={ process.env.PUBLIC_URL + icons.home } />
                                <span className='sr-only'>Home</span>
                            </a>
                        </li>
                        <li className={ page.samples ? `${styles.active}` : '' }>
                            <a href="#samples">    
                                <Icon size='icon--xs' path={ process.env.PUBLIC_URL + icons.sample } />
                                <span className='sr-only'>Samples</span>
                            </a>
                        </li>
                        <li className={ page.contact ? `${styles.active}` : '' }>
                            <a href="#contact">
                                <Icon size='icon--xs' path={ process.env.PUBLIC_URL + icons.contact } />
                                <span className='sr-only'>Contact</span>
                            </a>
                        </li>
                        <div className={ styles.bar }></div>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default NavIon;
