import {useState, useRef, useEffect} from 'react';
import {IFormStatus, submissionStatus, getSubmissionStatus} from '../Contact';
import styles from './ContactForm.module.scss';

interface IForm {
	'bot-field': string;
	name: string;
	email: string;
	subject: string;
	message: string;
}
interface IKeyable {
	[key: string]: string;
}
interface Props {
	formStatus: IFormStatus;
	setFormStatus: React.Dispatch<React.SetStateAction<IFormStatus>>;
}

const ContactForm: React.FC<Props> = ({formStatus, setFormStatus}) => {
	const [form, setForm] = useState<IForm>({
		'bot-field': '',
		name: '',
		email: '',
		subject: '',
		message: '',
	});
	const [errors, setErrors] = useState<IKeyable>({});
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isSent, setIsSent] = useState(false);
	const formRef = useRef<HTMLFormElement>(null);

	useEffect(() => {
		let isMounted = true;
		let timer:  NodeJS.Timeout;
		const inputs = formRef.current!.querySelectorAll('input');
		const textarea = formRef.current!.querySelector('textarea');

		inputs.forEach((input) => {
			input.addEventListener('mouseenter', () => {
				input.focus();
			});
			input.addEventListener('mouseout', () => {
				input.blur();
			});
		});
		textarea?.addEventListener('mouseenter', () => {
			textarea.focus();
		});
		textarea?.addEventListener('mouseout', () => {
			textarea.blur();
		});

		if (Object.keys(errors).length === 0 && isSubmitted) {
			fetch('/', {
				method: 'POST',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				body: encode({
					'form-name': 'contact-form-nozomione',
					...form,
				}),
			})
				.then(() => {
					if (isMounted) {
						setIsSent(true);
						timer = setTimeout(()=> {
							setIsSubmitted(false);
							setForm({
								'bot-field': '',
								name: '',
								email: '',
								subject: '',
								message: '',
							});
							submissionStatus ? 
								setFormStatus({
									success: getSubmissionStatus(),
									failed: false,
								}) :
								setFormStatus({
									success: true,
									failed: false,
								});
						}, 380);
						localStorage.setItem('submission-status', 'true');
						localStorage.setItem('submission-date', JSON.stringify(Date.now()));
					}
				})
				.catch((error) => {
					console.error(error);
					setIsSubmitted(false);
					setIsSent(false);
					setFormStatus({
						success: false,
						failed: true,
					});
				});
		}

		return () => {
			isMounted = false;
			clearTimeout(timer);
		};
	}, [errors, isSubmitted, form, setFormStatus]);

	const validate = (form: IForm) => {
		let formErrors: IKeyable = {};
		if (!form.name) {
			formErrors.name = 'Name is required';
		}
		if (!form.email) {
			formErrors.email = 'Email is required';
		}
		if (!form.subject) {
			formErrors.subject = 'Subject is required';
		}
		if (!form.message) {
			formErrors.message = 'Message is required';
		}
		return formErrors;
	};

	const encode = (data: any) => {
		return Object.keys(data)
			.map(
				(key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
			)
			.join('&');
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setErrors(validate(form));
		setIsSubmitted(true);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {name, value} = e.target;

		if (value.length > 0 && errors[name]) {
			setErrors({
				...errors,
				[name]: '',
			});
		}

		setForm({
			...form,
			[name]: value,
		});
	};

	const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const {name, value} = e.target;

		if (value.length > 0 && errors[name]) {
			setErrors({
				...errors,
				[name]: '',
			});
		}

		setForm({
			...form,
			[name]: value,
		});
	};

	return (
		<div className={ isSent ? `${styles['contact-form']} ${styles.success}` : styles['contact-form']}>
			{formStatus.failed && 
						<span className={ styles['form-error']}>
							<strong>Error:</strong> Message could not be sent at this time. Sorry for the inconvenience.
							Please try again later.
						</span>
					}
			<form
				ref={formRef}
				name='contact-form-nozomione'
				onSubmit={handleSubmit}
				data-netlify='true'
				netlify-honeypot='bot-field'
			>
				<input name='bot-field' hidden />
				<div className={styles['form-control']}>
					{errors.name && <span>* {errors.name}</span>}
					<input
						type='text'
						id='name'
						name='name'
						value={form.name}
						onChange={handleInputChange}
						placeholder="&nbsp;"
					/>
					<label htmlFor='name'>Name</label>
				</div>
				<div className={styles['form-control']}>
					{errors.email && <span>* {errors.email}</span>}
					<input
						type='email'
						id='email'
						name='email'
						value={form.email}
						onChange={handleInputChange}
						placeholder="&nbsp;"
					/>
					<label htmlFor='name'>Email</label>
				</div>
				<div className={styles['form-control']}>
					{errors.subject && <span>* {errors.subject}</span>}
					<input
						type='text'
						id='subject'
						name='subject'
						value={form.subject}
						onChange={handleInputChange}
						placeholder="&nbsp;"
					/>
					<label htmlFor='subject'>Subject</label>
				</div>
				<div className={styles['form-control']}>
					{errors.message && <span>* {errors.message}</span>}
					<textarea
						id='message'
						name='message'
						value={form.message}
						onChange={handleTextareaChange}
						placeholder="&nbsp;"
					></textarea>
					<label htmlFor='message'>Message</label>
				</div>
				<div style={{paddingTop: '16px'}} className={styles['form-control']}>
					<button type='submit'>Send</button>
				</div>
			</form>
		</div>
	);
};

export default ContactForm;
