import {useState, useRef, useEffect} from 'react';
import {useSetPage, useMatchMedia} from '../../hooks';
import {Socials} from '../../components/shared';
import {_setObservable, _setH1Text} from '../../utils/helpers';
import {ContactForm} from './contact-form';
import { SuccessAnimation } from './contact-form/success-animation';
import sharedStyles from '../Pages.module.scss';
import styles from './Contact.module.scss';

export interface IFormStatus {
	success: boolean;
	failed: boolean;
}
export const submissionStatus = localStorage.getItem('submission-status');
export const getSubmissionStatus = () => 
	localStorage.getItem('submission-status') === 'true';

const Contact: React.FC = () => {
	const setPage = useSetPage()!;
	const maxW950 = useMatchMedia('(max-width:950px)');
	const [formStatus, setFormStatus] = useState<IFormStatus>({
		success: false,
		failed: false,
	});
	const screenRef = useRef<HTMLElement>(null);
	const submissionDate = localStorage.getItem('submission-date');
	
	useEffect(() => {
		if(submissionStatus) {
			setFormStatus({
				...formStatus,
				success: getSubmissionStatus()
			})
		}
		
		const h1 = screenRef.current!.querySelector('h1') as HTMLHeadingElement;
		_setH1Text('#contact', h1.textContent!);

		const observer = _setObservable(
			(entries: IntersectionObserverEntry[]) => {
				if (entries[0].isIntersecting) {
					setPage({
						home: false,
						samples: false,
						contact: true,
					});
					entries[0].target.classList.add(
						`${sharedStyles.active}`,
						`${styles.active}`
					);
				} else {
					entries[0].target.classList.remove(
						`${sharedStyles.active}`,
						`${styles.active}`
					);
				}
			},
			{threshold: 0.4}
		);

		observer.observe(screenRef.current!);

		return () => {
			observer.disconnect();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setPage, maxW950]);

	useEffect(() => {
	}, [formStatus]);

	return (
		<section id='contact' className={styles.contact} ref={screenRef}>
			<div className={`${sharedStyles.container} ${styles.container}`}>
				<h1>Contact</h1>
				<div className={`${sharedStyles.panel} ${styles.panel}`}>
					<div className={styles['text-box']}>
						<h2>Get in touch:</h2>
						<Socials direction='y' text={true} />
					</div>
					{!formStatus.success && <ContactForm formStatus={formStatus} setFormStatus={setFormStatus} />}
					{formStatus.success && (
						<div className={`${styles.screen} ${styles.success}`}>	
							<div className={styles.message}>
								<SuccessAnimation />
								<div className={styles.text}>	
									<p>Your message has been sent.
									<br/>I'll get back to you shortly.</p>
								</div>
							</div>
							<span className={ styles.info }>
								&#9432; You sent a message on { submissionDate && 
									new Date(JSON.parse(submissionDate)).toDateString() }
							</span>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default Contact;
